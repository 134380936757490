import { useStore } from "vuex";

export function useECommerceProduct(productProp?: object) {
	const store = useStore();

	const eCommerceProduct = computed(() => productProp || store.state.ecommerce.product);

	// 'product' is desirable, but 'eCommerceProduct' is used many places where 'product' would conflict
	return { eCommerceProduct, product: eCommerceProduct };
}
